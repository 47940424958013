.App {
  text-align: left;
  margin-top: 20px;
  margin-left: 30px;
  padding-bottom: 20px;
}

.Loader {
  padding-top: 20px;
}

.Outer {
  display: flex;
  flex-direction: row;
}

.Inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 15px;
}

.Screenshot {
  max-width: 250px;
  width: 100%;
}

.Welcome {
  grid-area: main-top;
}

.Login {
  grid-area: main-bottom;
}

.LoginBtn {
  padding: 12px !important;
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 30% !important;
  height: 10% !important;
  color: #1db954;
  cursor: pointer;
  font-size: 1.8vmin !important;
  transition: all 0.25s ease;
  font-weight: bold;
  left: auto !important;
  right: auto !important;
  margin-top: 6px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.LoginBtn:hover {
  background-color: #1db954 !important;
}

.Header {
  border-bottom: 1px solid #b3ffff;
  background-color: #ccffff;
}

.HeaderText {
  display: inline-block;
  color: rgba(122, 0, 153, 0.6);
  text-align: center;
  font-size: 14px;
  margin-left: 2px;
}

.HeaderPic {
  display: inline-block;
  padding: 3px 3px 3px 3px;
  max-width: 25px;
  width: 100%;
}

.Footer{
position: fixed;
 left: 0;
 bottom: 0;
 width: 100%;
  border-bottom: 20px solid #f2f2f2;
}

.FaqList {
  list-style-type: none;
}

.Faq{
  border-top: 2px solid #b3ffff;
  margin-top: 35px;
  padding-top: 8px;
}

input[type="checkbox"] {
  display: none;
}

.PlaylistRow {
  position: relative;
  display: inline-block;
  border: 1px solid #e6e6e6;
  border-radius: 26px;
  min-width: 200px;
  max-width: 800px;
  height: 66px;
  margin: 6px;
  padding: 7px 22px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PlaylistRow.checked {
  border: 1px solid #1db954;
}

.spanCheck {
  visibility: hidden;
  position: relative;
  left: -8px;
  font-weight: bold;
}

input[type="checkbox"]:checked + .spanCheck {
  visibility: visible;
}

.PlaylistRow:hover {
  border: 1px solid #1db954;
}

.BtnPadding{
padding-bottom: 20px;
}

.SubmitBtn {
  display: block;
  text-align: center;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 20%;
  height: 8%;
  color: #1db954;
  cursor: pointer;
  font-size: 2vmin;
  transition: all 0.25s ease;
  font-weight: bold;
}

.SubmitBtn:hover {
  background-color: #1db954 !important;
}

.RedirectBtn{
  padding: 0px;
}

.HomePageBtn{

}
